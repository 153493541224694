//import i18next from 'i18next';
import React from 'react';
//import en from './i18n/en';
//import tr from './i18n/tr';
//import ar from './i18n/ar';

//i18next.addResourceBundle('en', 'examplePage', en);
//i18next.addResourceBundle('tr', 'examplePage', tr);
//i18next.addResourceBundle('ar', 'examplePage', ar);


const HomeConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/',
            component: React.lazy(() => import('./Home'))
        }
    ]
};

export default HomeConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
