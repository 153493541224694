import { Navigate } from 'react-router-dom';
import FuseUtils from '../../@fuse/utils';
import pagesConfigs from '../main/pages/pagesConfigs';
import HomeConfig from '../main/home/HomeConfig'

const routeConfigs = [
	...pagesConfigs,
	HomeConfig
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Navigate to="/" />
	},
];

export default routes;
