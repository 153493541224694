//import i18next from 'i18next';
import React from 'react';
//import en from './i18n/en';
//import tr from './i18n/tr';
//import ar from './i18n/ar';

//i18next.addResourceBundle('en', 'examplePage', en);
//i18next.addResourceBundle('tr', 'examplePage', tr);
//i18next.addResourceBundle('ar', 'examplePage', ar);


const BriefcaseConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/briefcase',
            component: React.lazy(() => import('./Briefcase'))
        }
    ]
};

export default BriefcaseConfig;
