
import AppContext from './AppContext';
import routes from './app-configs/routesConfig';
import { Routes, Route } from 'react-router-dom';

const App = () => {
	return (
		<AppContext.Provider value={{ routes }}>
			<Routes>
				{routes.map((e: any) => (
					<Route path={e.path} element={<e.component />} />
				))}
			</Routes>
		</AppContext.Provider>
	);
};

export default App;