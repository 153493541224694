import BriefcaseConfig from "./Briefcase/BriefcaseConfig";
import ContactConfig from "./Contact/ContactConfig";
import ExampleConfig from "./Test/ExampleConfig";

const pagesConfigs = [
    ExampleConfig,
    BriefcaseConfig,
    ContactConfig
];

export default pagesConfigs